<template>
  <div class="modalClass" ref="container">
    <div class="custom-board" v-show="showCustomBoard">
      <a-input-number
        style="width: 100%; line-height: 1"
        v-model:value="customTimeDay"
        :min="1"
        :max="30"
        placeholder="请输入提前天数"
      />
      <!--     format="HH:mm"   -->
      <a-time-picker
        style="width: 100%"
        format="HH:mm"
        v-model:value="customTimePick"
      />
      <div class="custom-row">
        <a-button @click="closeBoard">取消</a-button>
        <a-button @click="addEarlyList" type="primary">确认</a-button>
      </div>
    </div>
    <a-modal
      :visible="visible"
      :title="addOrEdit !== 'add' ? '编辑待办' : '新增待办'"
      :mask-closable="false"
      :width="564"
      @ok="handleOk"
      centered
      @cancel="handleCancel"
      forceRender
      :destroyOnClose="true"
      :getContainer="() => container"
      class="issModal"
    >
      <a-form
        ref="formRef"
        layout="vertical"
        style="width: 100%"
        :model="form"
        :rules="rules"
      >
        <a-form-item label="" name="content">
          <a-textarea
            v-model:value="form.content"
            placeholder="请输入待办内容"
            showCount
            :maxlength="200"
            :rows="4"
          />
        </a-form-item>
        <a-button @click="openContactAdd">创建联系人</a-button>
        <!--     todo 一键添加全部联系人   -->
        <a-button @click="addAllContacts" type="primary" class='ml-10'>批量添加联系人</a-button>
        <!--      下拉选择中才显示在这里    -->
        <div class="dataListClass">
          <div
            v-if="dataList?.length > 0"
            @click="handleEmpty()"
            class="cursor-p empty"
          >
            一键清空
          </div>
          <div v-for="(item, index) in dataList" :key="item.id">
            <div class="name">
              <!--              孙大松 -->
              {{ item.name }}
              <CloseOutlined @click="deleteName(index)" />
            </div>
          </div>
        </div>

        <a-form-item label="" class="formItemClass">
          <!--       输入框输入值，调取接口，获取数据，选择数据是个list,然后遍历list展示   -->
          <a-input
            @click.stop
            v-model:value="form.inputValue"
            placeholder="请添加联系人"
            @change="handleChange(form.inputValue)"
            @blur="handleBlur()"
            @focus="handleFocus()"
            :allowClear="true"
          >
          </a-input>
          <!-- 这里需要展示下拉  :class="{selectListtNone: form.contactIdList.length > 0}"  -->

          <div v-if="showxiala" class="namename" ref="selectBox">
            <div v-for="i in selectNameList" :key="i.id">
              <div class="selectList">
                <div class="selectName" @click="selectName(i)">
                  {{ i.name }}
                </div>
              </div>
            </div>
          </div>
          <div v-if="showEmpty" class="namename" ref="selectBox">
            <a-empty :image="simpleImage" />
          </div>
        </a-form-item>

        <a-row>
          <a-col :span="12">
            <a-form-item label="" name="reminderTime">
              <span class="time-tip-text">待办时间:</span>
              <!-- :getCalendarContainer="triggerNode => triggerNode.parentNode" -->
              <a-date-picker
                v-model:value="form.reminderTime"
                value-format="yyyy-MM-DD HH:mm"
                show-time
                :disabled-date="disabledDate"
                :disabled-time="disabledReminderTime"
                placeholder="请选择待办时间"
                :allowClear='false'
                style="width: 100%"
                class="phoneCalssRight"
                :inputReadOnly="true"
                @blur="handleBlur('reminderTime')"
                @change="(value) => handleChangeReminderTime(value)"
                @ok="
                  {
                    form.cycleReminderTime = form.reminderTime;
                  }
                "
              />
            </a-form-item>
          </a-col>
          <a-col :span="11" class="colCalss">
            <!--   提前提醒时间    -->
            <a-form-item
              label=""
              name="reminderCycle"
              class="dropdownRenderSel"
            >
              <span class="time-tip-text">提醒时间:</span>
              <a-date-picker
                v-model:value="form.cycleReminderTime"
                value-format="yyyy-MM-DD HH:mm"
                show-time
                :disabled="!form.reminderTime"
                :getCalendarContainer="triggerNode => triggerNode.parentNode"
                :disabled-date="disabledCycleDate"
                :disabled-time="disabledCycleTime"
                placeholder="请选择提醒时间"
                style="width: 100%"
                :allowClear='false'
                class="phoneCalssRight"
                :inputReadOnly="true"
                @change='(value)=>cycleReminderTimeChange(value)'
                @blur="handleBlur('reminderCycle')"
              />
              <!-- <a-select
                v-model:value="form.reminderCycle"
                placeholder="请选择提醒时间"
                :open="earlyListOpen"
                :options="
                  spEarlyList.map(item => ({
                    value: item.itemCode,
                    label: item.itemNameCn,
                  }))
                "
                @click="earlyListOpen = true"
              >
                <template #dropdownRender="{ menuNode: menu }">
                  <v-nodes :vnodes="menu" />
                  <div v-if="spEarlyList.length > 0">
                    <div ref="showCustom" class="">
                      <a-space>
                        <a-button
                          class="custom-btn"
                          style="padding: 3px 178px 3px 10px"
                          type="text"
                          @click="chooseTime"
                          @mousedown.prevent
                          ref="customBtn"
                        >
                          自定义
                        </a-button>
                      </a-space>
                    </div>

                    <div class="custom-row custom-btn">
                      <a-button @click="closeEarlySelect">收起</a-button>
                      <a-button @click="closeEarlySelect">取消</a-button>
                      <a-button @click="closeEarlySelect" type="primary">确认</a-button>
                    </div>
                  </div>
                </template>
              </a-select> -->

            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label="" >
          <div class="blessingText">
            <div class='textLabel'>待办提醒时间</div>
            <div class='tR'>
              <div
                v-for="item in earlyList"
                :key="item.id"
                class="itemsBel cursor-p"
                @click="handleItems(item)"
                :class="{ showBor: form.reminderCycle === item.itemCode }"
              >
                <div class="text">{{ item.itemNameCn }}</div>
              </div>
            </div>
          </div>
        </a-form-item>

        <!--   提醒方式   -->
        <a-form-item label="" name="reminderMethodList">
          <a-select
            mode="multiple"
            autoClearSearchValue
            v-model:value="form.reminderMethodList"
            placeholder="请选择提醒方式"
            :filter-option="
              (inputValue, option) =>
                handleFilterOption(inputValue, option, item)
            "
            @change="(value) => ChangeReminderMethodList(value)"
          >
            <a-select-option
              v-for="item in remindList"
              :key="item"
              :value="item.itemCode"
            >
              {{ item.itemNameCn }}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item
          v-for="(item, index) in form.subItemsList"
          :key="item.key"
          :label="index === 0 ? '' : ''"
          :name="['subItemsList', index, 'phone']"
          :rules="rulesPhone"
          :class="{
            bt: index === 0 ? true : false,
          }"
          class="subItem"
        >
          <a-input
            v-model:value="item.subContent"
            placeholder="请输入子待办"
            style="width: 55%; margin-right: 8px; height: 32px"
          />
          <!--      :disabled="dynamicValidateForm.domains.length === 1"
                       v-if="dynamicValidateForm.domains.length > 1"
            -->
          <a-button
            style="width: 195px; margin-right: 8px"
            v-if="item.reminderTime == null"
            @click="item.reminderTime = ''"
            >添加时间</a-button
          >
          <a-date-picker
            v-model:value="item.reminderTime"
            value-format="yyyy-MM-DD HH:mm"
            show-time
            :disabled-date="disabledCycleDateOther"
            :disabled-time="disabledCycleTime"
            placeholder="请选择提醒时间"
            class="phoneCalssRight"
            style="margin-right: 8px"
            :inputReadOnly="true"
            v-else
          />
          <MinusCircleFilled
            class="dynamic-delete-button"
            @click="removeDomain(item)"
          />
        </a-form-item>
        <!--          v-bind="formItemLayoutWithOutLabel"-->
        <a-form-item class="subItemBut">
          <a-button type="dashed" style="width: 100%" @click="addDomain">
            <PlusOutlined />
            添加待办事项
          </a-button>
        </a-form-item>
      </a-form>
      <all-contacts
        :visible="allContactsVisible"
        :init='allContactsVal'
        @fnOk="handleAllContacts"
      />
    </a-modal>
    <contactAdd
      :visible="contactAddShow"
      :backContact="true"
      @fnOk="handleContactAdd"
      @update:visible="closeContactAdd"
    />
  </div>
</template>
<script>
import {
  reactive,
  toRefs,
  watch,
  onMounted,
  ref,
  onBeforeUnmount,
  getCurrentInstance,
} from 'vue';
import { useStore } from 'vuex';
import {
  MinusCircleFilled,
  PlusOutlined,
  CloseOutlined,
} from '@ant-design/icons-vue';
import {
  Form,
  message,
  DatePicker,
  TimePicker,
  Select,
  Row,
  Col,
  Space,
  InputNumber,
  Empty,
} from 'ant-design-vue';
import contactAll from '@/api/contactAll';
import todayApi from '@/api/today';
import moment from 'moment/moment';
import { local } from '@/utils/storage';
import contactAdd from '@/views/contacts/contactAall/addPage';
import allContacts from '@/components/allContacts';
import { mergeAndUnique } from '@/utils';

// import {useRouter} from "vue-router";

export default {
  components: {
    MinusCircleFilled,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADatePicker: DatePicker,
    PlusOutlined,
    ARow: Row,
    ACol: Col,
    CloseOutlined,
    // ASpace: Space,
    ATimePicker: TimePicker,
    AInputNumber: InputNumber,
    AEmpty: Empty,
    // VNodes: (_, { attrs }) => attrs.vnodes,
    contactAdd,
    allContacts,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    // const router = useRouter();
    const contactAddShow = ref(false);
    const container = ref();
    const formRef = ref();
    const customBtn = ref();
    const showCustom = ref();
    const store = useStore();
    const selectBox = ref();
    const { proxy } = getCurrentInstance();
    const userId = store.state.account.user.id;
    const form = reactive({
      content: '',
      leadPool: [],
      reminderTime: null,
      lastInteractionTime: '',
      cycleReminderTime: null,
      // 自定义准时
      inAdvanceDays: null,
      time: '',
      // 人员类型 0-个人 1-脉圈
      personnelType: null,
      contactIdList: [],
      connectionsId: '',
      contentName: [],
      // 子待办数组
      subItemsList: [],
      type: '',
      dataType: '',
      // 主待办id
      todoId: '',
      userId: store.state.account.user.id,
      reminderMethodList: [],
      inputValue: '',
      // 联系人详情页面新增需要传
      contactId: '',
      reminderCycle: '0',

    });

    const state = reactive({
      allContactsVisible: false,
      allContactsVal: {},
      earlyList: [],
      remindList: [],
      // 联系人下拉
      contactList: [],
      len: false,
      selectNameList: [],
      dataList: [],
      showxiala: false,
      showEmpty: false,
      comeFrom: '',
      addOrEdit: 'add',
      spEarlyList: [],
      showCustomBoard: false,
      earlyListOpen: false,
      boardTop: 0,
      boardLeft: 0,
      // time: {},
      customTimeDay: null,
      customTimePick: '',
      loading: false,
    });

    // today页面 保持联系、待办、最近待办、待办主页面、联系人页面待办
    watch(
      () => props.init,
      init => {
        state.addOrEdit = init.type;
        // console.log('新增编辑待办', init, state.addOrEdit);
        // 在联系人详情或者脉圈详情中添加得待办，需要拿到当下联系人id或脉圈详情id添加到 contactIdList
        if (init.contactId) {
          // form.personnelType = 0;
          form.contactId = init.contactId[0];
        }
        // 出来来自不同页面的新增联系人详情、待办主页面
        // contact  联系人  schedule 最近待办  backlog 待办主页面
        if (init.comeFrom) {
          state.comeFrom = init.comeFrom;
        }
        if (init.id) {
          // 在今天页面进来的新增和编辑的处理
          if (init.type === 'add') return (state.dataList = [init]);

          todayApi.getMatter('', init.id).then(res => {
            if (res) {
              Object.assign(form, res);

              // 自定义时间处理
              // if (res.reminderCycle === 'custom') {
              //   let arr = {
              //     itemCode: 'custom',
              //     itemNameCn: `提前${init.inAdvanceDays}天(${init.time})`,
              //   };
              //   form.reminderCycle = arr.itemNameCn;
              //   state.spEarlyList.push(arr);
              //   console.log('state.spEarlyList', state.spEarlyList);
              // }

              state.dataList = res.personnelVoList;
              if (res.reminderMethodList) {
                form.reminderMethodList = res.reminderMethodList;
              } else {
                form.reminderMethodList = [];
              }
            }
          });
        }
      },
      { deep: true }
    );

    // 提前提醒时间
    contactAll.getTree('', { code: 'REMINDER_CYCLE' }).then(res => {
      if (res) {
        state.earlyList = res;
      }
    });
    // 提醒方式
    contactAll.getTree('', { code: 'REMINDER_METHOD' }).then(res => {
      if (res) {
        state.remindList = res;
        form.reminderMethodList.push(state.remindList[0].itemCode)
      }
    });

    //
    // todayApi
    //     .queryContactByName('', {
    //     })
    //     .then(res => {
    //       console.log('handleFocus获取焦点', res);
    //       // state.showxiala = true
    //
    //       Object.assign(state.selectNameList, res)
    //     });
    // const colseSift = () => {
    // console.log('点击事件', state.showxiala)
    // if (state.showxiala) {
    //   state.showxiala = state.showxiala;
    //   console.log('点击事件56', state.showxiala)
    // }
    // };

    //获取当前时间
    const getCurrentData = () => {
      const now = new Date();
      const after = new Date(now.getTime() + 60 * 60 * 1000);
      const year = now.getFullYear();
      const month =
        after.getMonth() + 1 >= 10
          ? after.getMonth() + 1
          : '0' + (after.getMonth() + 1);
      const day =
        after.getDate() >= 10 ? after.getDate() : '0' + after.getDate();
      const hour =
        after.getHours() >= 10 ? after.getHours() : '0' + after.getHours();
      // const minute =
      //   now.getMinutes() >= 10 ? now.getMinutes() : '0' + now.getMinutes();
      const minute = '00';
      const second = '00';

      form.reminderTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      form.cycleReminderTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
      form.lastInteractionTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;
    };

    // 处理待办提示时间
    const handleTime = (time) => {
      const year = time.getFullYear();
      const month =
        time.getMonth()  + 1 >= 10
          ? time.getMonth() + 1
          : '0' + (time.getMonth() + 1);
      const day =
        time.getDate() >= 10 ? time.getDate() : '0' + time.getDate();
      const hour =
        time.getHours() >= 10 ? time.getHours() : '0' + time.getHours();
      const minute =
        time.getMinutes() >= 10 ? time.getMinutes() : '0' + time.getMinutes();
      const second =
        time.getSeconds() >= 10 ? time.getSeconds() : '0' + time.getSeconds();
      return `${year}-${month}-${day} ${hour}:${minute}:${second}`;

    };
    // // 全局创建一个点击事件:bodyCloseMenus
    onMounted(() => {
      getCurrentData();

      document.addEventListener('click', e => {
        if (selectBox.value) {
          // console.log('selectBox222', selectBox, e.target);
          if (!selectBox.value.contains(e.target)) {
            // console.log('selectBox', selectBox, e.target);
            state.showxiala = false;
            state.showEmpty = false;
            form.inputValue = '';
          }
        }
      });
    });
    // // 在页面注销前，将点击事件给移除
    onBeforeUnmount(() => {
      // console.log('点击事件66', )
      // document.removeEventListener('click', colseSift);
    });

    const rules = {
      content: [
        {
          required: true,
          message: '请输入待办内容',
          trigger: 'change',
        },
        { max: 200, message: '字符长度不能超过48' },
      ],
      reminderTime: [
        {
          required: true,
          message: '不能为空',
          trigger: 'change',
        },
      ],
    };
    const rulesPhone = {
      // required: true,
      // message: 'domain can not be null',
      trigger: 'change',
      validator: async (rule, value) => {
        // console.log('rule', rule, value);
        let maxLength50Regex = /^.{1,40}$/;
        if (!maxLength50Regex.test(value)) {
          return Promise.reject('子待办最多只能输入40个字符');
        }
      },
    };

    const removeDomain = item => {
      let index = form.subItemsList.indexOf(item);
      if (index !== -1) {
        form.subItemsList.splice(index, 1);
      }
    };

    const addDomain = () => {
      if (form.subItemsList.length === 6) {
        return message.error('最多只可设置6个子待办');
      } else {
        form.subItemsList.push({
          subContent: '',
          key: Date.now(),
          // 待办是否完成 0-未完成 1-已完成
          completed: null,
          reminderTime: null,
        });
      }
    };

    const handleCancel = () => {
      // context.emit('fnOk', false);
      context.emit('update:visible', false);
      if (state.addOrEdit === 'add') {
        formRef.value.resetFields();
        // 新增完成需要清空的
        state.dataList = [];
        form.subItemsList = [];
        form.contactId = '';
        form.connectionsId = '';
      }

      //   关闭自定义弹窗
      closeEarlySelect();
    };

    const getData = res => {
      let notFoundItems = []; // 存储在list数组中存在但在a数组中不存在的项的新数组
      // 遍历 list 数组
      res.forEach(item => {
        // 使用 JSON.stringify 将对象转换为字符串，然后检查当前项是否在 a 数组中
        if (!state.dataList?.some(aItem => aItem.id === item.id)) {
          // 如果不在，将该项添加到 notFoundItems 数组中
          notFoundItems.push(item);
        }
      });
      // 打印在list数组中存在但在a数组中不存在的项
      if (notFoundItems.length > 0) {
        state.showxiala = true;
        state.showEmpty = false;
        state.selectNameList = notFoundItems;
      } else {
        state.showxiala = false;
        state.showEmpty = true;
      }
    };
    const closeBoard = () => {
      setTimeout(() => {
        state.customTimeDay = null;
        state.customTimePick = '';
        state.showCustomBoard = false;
      }, 100);
    };

    const closeEarlySelect = () => {
      setTimeout(() => {
        state.earlyListOpen = false;
        state.showCustomBoard = false;
      }, 100);
    };

    //点击自定义打开时间选择面板
    const chooseTime = e => {
      console.log('e', e);
      let rect = customBtn.value.$el.getBoundingClientRect();
      state.showCustomBoard = true;
      state.boardTop = rect.bottom - rect.height + 'px';
      state.boardLeft = rect.right + 'px';
    };

    //往提醒时间列表中添加新的选项
    const addEarlyList = () => {
      console.log(
        'state.customTimePick',
        state.customTimePick,
        state.customTimeDay
      );
      // debugger
      if (!state.customTimePick._d) {
        return message.error('请选择自定义时间');
      }
      let hour = state.customTimePick._d?.getHours();
      let min = state.customTimePick._d?.getMinutes();
      // let sec = state.customTimePick._d.getSeconds();
      // :${sec > 10 ? sec : '0' + sec})
      let arr = {
        itemCode: 'custom',
        itemNameCn: `提前${state.customTimeDay}天(${
          hour >= 10 ? hour : '0' + hour
        }:${min >= 10 ? min : '0' + min}:00)`,
      };
      form.time = `${hour >= 10 ? hour : '0' + hour}:${
        min >= 10 ? min : '0' + min
      }:00`;

      if (!state.customTimeDay) {
        return message.error('请输入提前天数');
      }

      form.inAdvanceDays = parseInt(state.customTimeDay);
      //将之前添加的自定义时间清除
      state.spEarlyList = state.spEarlyList.filter(item => {
        if (item.itemCode !== 'custom') {
          return item;
        }
      });
      state.spEarlyList.push(arr);
      state.customTimeDay = null;
      state.customTimePick = null;
      closeBoard();
    };

    const openContactAdd = () => {
      contactAddShow.value = true;
    };

    const handleContactAdd = value => {
      console.log(value);
      contactAddShow.value = value.visible;
      state.dataList.push({
        id: value.id,
        name: value.name,
      });
    };
    const closeContactAdd = () => {
      contactAddShow.value = false;
    };

    const range = (start, end) => {
      const result = [];
      for (let i = start; i < end; i++) {
        result.push(i);
      }
      return result;
    };

    // 不同一天的快捷处理
    const butongData = (itemCode) => {
      const now = new Date();
      // 同一天, 当前时间 start 和 end 结束时间：即待办时间
      // 在当前时间段内可以点击，否则不能点击
      let time = new Date(form.reminderTime);
      // 快捷选择 待办提醒时间
      let addMinute = new Date(time.setMinutes(time.getMinutes() - parseInt(itemCode)));
      let nowTimestamp = now.getTime();
      let timeTimestamp = time.getTime();
      let addMinutestamp = addMinute.getTime();

      // 快捷选择时间大于当前时间，并且少于待办时间
      if (addMinutestamp > nowTimestamp && addMinutestamp <= timeTimestamp ) {
        form.cycleReminderTime = handleTime(addMinute)
        form.reminderCycle = itemCode
      } else {
        // 准时可选
        if (timeTimestamp === addMinutestamp && itemCode === '0') {
          form.reminderCycle = itemCode
        } else {
          return message.error('提醒时间已发生请重新选择')
        }
      }
    };

    // 同一天时间不可选交互提醒
    const getTimeNone = (itemCode) => {
      const now = new Date();
      // console.log('now', now, new Date(form.reminderTime))
      if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
        // 同一天时提前一天和提前三天不可选
        if (itemCode === '1440' || itemCode === '4320') {
          return message.error('提醒时间已发生请重新选择')
        } else {
          butongData(itemCode)
        }
      } else {
        butongData(itemCode)

      }

    };

    const addAllContacts = () => {
      state.allContactsVisible = true;
      state.allContactsVal = {}
    };

    return {
      container,
      ...toRefs(state),
      form,
      rules,
      rulesPhone,
      formRef,
      proxy,
      customBtn,
      showCustom,
      selectBox,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      handleCancel,
      removeDomain,
      addDomain,
      closeBoard,
      closeEarlySelect,
      chooseTime,
      addEarlyList,
      contactAddShow,
      openContactAdd,
      handleContactAdd,
      closeContactAdd,
      addAllContacts,
      range,
      disabledDate: current => {
        // moment().subtract(1, 'days').endOf('day');    moment().endOf('day');
        return current && current < moment().subtract(1, 'days').endOf('day');
      },
      disabledCycleDate: current => {
        let reminderTime = moment(form.reminderTime);
        return current < moment() || current > reminderTime;
      },
      disabledCycleDateOther: current => {
        let reminderTime = moment(form.reminderTime);
        return current < moment().subtract(1, 'day') || current > reminderTime;
      },
      // 提醒时间时分秒限制
      disabledCycleTime: () => {
        const now = new Date();
        let h = 23;
        let m = 60;
        let s = 60;
        if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
          h = moment(form.reminderTime).hour(); //同一天 禁用小时
          if (moment(form.cycleReminderTime).hour() === moment(form.reminderTime).hour()) {
            m = moment(form.reminderTime).minute(); //同一小时 禁用分钟的选择
            if (moment(form.cycleReminderTime).minute() === moment(form.reminderTime).minute()) {
              s = moment(form.reminderTime).second(); //同一分钟 禁用秒的选择
            }
          }
        }

        return {
          disabledHours: () => range(h + 1, 24),
          disabledMinutes: () => range(m + 1, 60),
          disabledSeconds: () => range(s, 60),
        };
      },
      // 待办时间时分秒限制
      disabledReminderTime: () => {
        const now = new Date();
        let h = 23;
        let m = 60;
        let s = 60;
        // 同一天的时间禁用 待办时间跟当前时间对比
        if (form.reminderTime && moment(now).isSame(form.reminderTime, 'day')) {
          h = moment(now).hour(); //同一天 禁用小时

          if (moment(now).hour() === moment(form.reminderTime).hour()) {
            m = moment(now).minute(); //同一小时 禁用分钟的选择
            if (moment(now).minute() === moment(form.reminderTime).minute()) {
              s = moment(now).second(); //同一分钟 禁用秒的选择
            }
            return {
              disabledHours: () => range(0, h),
              disabledMinutes: () => range(0, m),
              disabledSeconds: () => range(s, 60),
            };

          } else {
            // 不同一小时分钟处理
            return {
              disabledHours: () => range(0, h),
              disabledMinutes: () => range(m, 60),
              disabledSeconds: () => range(s, 60),
            };
          }
        }

         },
      handleAllContacts(value) {
        // 拿到 value.list 已经选中的联系人数据，push到  dataList 里面，push之前要去重
        state.allContactsVisible = value.visible;
        let newAry = mergeAndUnique(state.dataList, value.list, 'id')
        state.dataList = newAry

      },

      handleChange(value) {
        // console.log('222222', value);
        // 联系人下拉 userId: store.state.account.user.id,
        todayApi
          .queryContactByName('', {
            name: value,
          })
          .then(res => {
            if (res) {
              getData(res);
            }
          });
      },
      ChangeReminderMethodList() {
        if (form.reminderCycle === 'not_remind') {
          form.reminderCycle = ''
        }
      },
      handleOk: () => {
        // 保存之前需要把 state.dataList 处理成 数组中只是含 id, 放在 form.contactIdList
        state.dataList?.map(item => {
          if (!form.contactIdList.includes(item.id)) {
            form.contactIdList.push(item.id);
          }
        });

        // if (form.contactIdList.length === 0) {
        //   return message.error('请添加联系人');
        // }
        form.subItemsList = form.subItemsList.filter(
          item => item.subContent !== ''
        );

        //  新增需要区分是从联系人、待办主页点进来
        // 新增完成后需要刷新对应的页面分页数据
        // form.personnelType = 0; 表示是联系人页面进来的
        if (form.reminderTime?.split(':').length === 2) {
          form.reminderTime += ':00';
        }
        if (form.cycleReminderTime?.split(':').length === 2) {
          form.cycleReminderTime += ':00';
        }
        form.subItemsList.forEach(item => {
          if (item.reminderTime && item.reminderTime.split(':').length === 2) {
            item.reminderTime += ':00';
          }
        });
        // 人脉圈删除后需要致空 connectionsId
        form.connectionsId = null;
        form.personnelType = null;

        // 保存需要校验提醒时间是否有效
        const now = new Date();
        let time = new Date(form.reminderTime);
        let cycleReminderTime = new Date(form.cycleReminderTime)
        // 快捷选择 待办提醒时间
        let nowTimestamp = now.getTime();
        let timeTimestamp = time.getTime();
        let cycleReminderTimetamp = cycleReminderTime.getTime();

        // 快捷选择时间大于当前时间，并且少于待办时间
        if (!(cycleReminderTimetamp > nowTimestamp && cycleReminderTimetamp <= timeTimestamp) ) {
          return message.error('提醒时间已发生请重新选择')
        }

        formRef.value.validateFields().then(() => {
          if (state.loading) return;
          state.loading = true;
          let type = 'addContactTodoMatter';

          form.id && (type = 'updataTodoMatter');
          // 新增待办
          todayApi[type]('', {
            userId: userId,
            ...form,
          })
            .then(res => {
              if (res) {
                // console.log('res', res);
                message.success(
                  state.addOrEdit !== 'add' ? '编辑待办成功' : '新增待办成功'
                );

                // 根据判单刷新待办列表数据
                if (state.comeFrom === 'contact') {
                  //  刷新联系人待办列表
                  proxy.$bus.emit('getReminderPage', true);
                } else if (state.comeFrom === 'backlog') {
                  //  刷新待办主列表
                  proxy.$bus.emit('getAddData', true);
                } else {
                  // schedule 最近待办
                  //  刷新最近待办列表
                  proxy.$bus.emit('getAddSchedule', true);
                }
                // 如果是保持联系在需要调取修改最后互动时间接口
                let getData = local.get('weekendData');
                // console.log('getData66', getData);
                if (getData && form.contactIdList.includes(getData.id)) {
                  // console.log('getData', getData);
                  const now = new Date();
                  const year = now.getFullYear();
                  const month =
                    now.getMonth() + 1 >= 10
                      ? now.getMonth() + 1
                      : '0' + (now.getMonth() + 1);
                  const day =
                    now.getDate() >= 10 ? now.getDate() : '0' + now.getDate();
                  const hour =
                    now.getHours() >= 10
                      ? now.getHours()
                      : '0' + now.getHours();
                  const minute =
                    now.getMinutes() >= 10
                      ? now.getMinutes()
                      : '0' + now.getMinutes();
                  const second = '00';
                  getData.lastInteractionTime = `${year}-${month}-${day} ${hour}:${minute}:${second}`;

                  contactAll
                    .contact('', { ...getData })
                    .then(res => {
                      if (res) {
                        // console.log('res', res);
                        localStorage.removeItem('weekendData');
                        // message.success('操作成功');
                        context.emit('fnOk', false);
                        state.loading = false;
                      }
                    })
                    .finally(() => {});
                }

                context.emit('fnOk', false);
                formRef.value.resetFields();
                //   关闭自定义弹窗
                closeEarlySelect();
                // 新增完成需要清空的
                state.dataList = [];
                form.contactIdList = [];
                form.subItemsList = [];
                form.contactId = '';
                form.connectionsId = '';
                state.loading = false;
              }else {
                state.loading = false;
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
      deleteName(index) {
        // console.log('deleteName', index);
        state.dataList.splice(index, 1);
      },
      handleEmpty() {
        state.dataList = [];
      },

      selectName(item) {
        if (state.dataList?.length > 0) {
          state.dataList.forEach(i => {
            if (i.id !== item.id) {
              state.dataList.push(item);
            }
          });
          // 数组对象去重的做法
          let unique = {};
          state.dataList.forEach(item => {
            unique[item['id']] = item;
          });
          state.dataList = Object.values(unique);
        } else {
          state.dataList = [];
          state.dataList.push(item);
        }

        form.inputValue = '';
        state.selectNameList = [];
        state.showxiala = false;
        // console.log('state.dataList', state.dataList);
      },

      handleFilterOption(inputValue, option) {
        return option.key.itemNameCn.indexOf(inputValue) >= 0;
      },

      // 失焦调取清空输入框内容和下拉内容
      handleBlur() {},
      handleFocus() {
        todayApi.queryContactByName('', {}).then(res => {
          // console.log('handleFocus获取焦点', res);
          if (res) {
            state.showxiala = true;
            getData(res);
          }
        });
      },

      //  选择待办提醒时间
      handleItems(item) {
        if(item.itemCode === 'not_remind') {
          // 不提醒情况提醒方式
          form.reminderMethodList =  []
          form.reminderCycle = item.itemCode
        } else {
          // 这里处理时间不可选的交互提醒
          getTimeNone(item.itemCode)
        }
      },
      handleChangeReminderTime (value) {
        if (value) {
          if (form.reminderCycle && form.reminderCycle !== 'not_remind') {
            let time = new Date(value);
            let addMinute = new Date(time.setMinutes(time.getMinutes() - parseInt(form.reminderCycle)));
            form.cycleReminderTime = handleTime(addMinute)
            form.reminderCycle = ''
          } else {
            form.cycleReminderTime = value
            form.reminderCycle = ''
          }
        }
      },
      cycleReminderTimeChange(value) {
        if (value) {
          form.reminderCycle = ''
          form.cycleReminderTime = value
        }
      },

    };
  },
};
</script>
<style lang="less" scoped>
.tabsRight {
  height: 550px;
}
.tabsRight /deep/ .ant-tabs-bar {
  margin: 4px 0px 0px 0px;
  border-bottom: 0.5px solid #eeeeee;
}
.content {
  //max-height: calc(100vh - 356px);
  //max-height: calc(100vh - 321px);
  //overflow-y: auto;
  height: 475px;
  overflow: auto;
  margin-top: 10px;
}
.listClass {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 14px 26px;
  border-bottom: 0.5px solid #eeeeee;
  .itemOne {
    // .name {
    // }
    .jinbi {
      margin-top: 8px;
    }
  }
  .itemTwo {
    width: 18%;
    text-align: right;
  }
}

.noneList {
  margin-top: 100px;
  text-align: center;
  color: #bfbfbf;
}

.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  top: 0px;
  font-size: 13px;
  //color: #999;
  color: #ff7b00;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #ff7b00;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
.subItemBut /deep/ .ant-btn > .anticon + span,
.ant-btn > span + .anticon {
  margin-left: 5px;
}

.modalClass /deep/ .ant-modal-body {
  //height: 580px;
  height: 526px;
  overflow: auto;
  padding: 24px 24px 0px 24px;
}
.colCalss {
  margin-left: 20px;
}
.modalClass /deep/ .ant-modal-footer {
  padding: 14px 24px 24px 24px;
  //padding: 24px;
}
.dataListClass {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 4px;
  margin-top: 16px;
  max-height: 68px;
  overflow: auto;
  //.nameList {

  .name {
    // width: 88px;
    height: 24px;
    border-radius: 4px;
    //background: #FFFFFF;
    background: #fffaf6;
    font-size: 12px;
    line-height: 20px;
    padding: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px 8px 8px 0px;
    gap: 4px;
  }

  //}
}

.nameListNone {
  display: none;
}

.formItemClass {
  position: relative;
}

.namename {
  max-height: 164px;
  width: 515px;
  overflow: auto;
  position: absolute;
  top: 24px;
  z-index: 2;
  margin-top: 16px;
  border: 1px solid #eeeff5;
  background: #fff;
  border-radius: 4px;
  .selectList {
    .selectName {
      height: 40px;
      //border-radius: 4px;
      background: #f5f6fa;
      font-size: 12px;
      line-height: 20px;
      //padding: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin: 0px 8px 8px 0px;
      //padding: 10px 16px;
      cursor: pointer;
      padding-left: 16px;
      &:hover {
        background: #fffaf6;
      }
    }
  }
}

.custom-btn {
  width: 100%;
  position: relative;
  text-align: left;
}

.custom-row {
  width: 100%;
  padding: 3px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.custom-board {
  width: 180px;
  padding: 10px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: #ffffff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  position: fixed;
  top: v-bind(boardTop);
  left: v-bind(boardLeft);
  z-index: 1001;
  // transform: translate(-100%, -100%);

  .custom-row {
    justify-content: space-between;
  }
}
//.dropdownRenderSel /deep/ .ant-select-item-option-active:not(.ant-select-item-option-disabled){
//  background-color: #fff6e6;
//}
.dropdownRenderSel /deep/.rc-virtual-list {
  max-height: 164px;
  overflow: auto;
}
.custom-btn /deep/ .ant-btn {
  padding: 4px 90px;
}
.empty {
  color: #ff7b00;
  margin-right: 12px;
}

/deep/ .ant-btn {
  font-size: 12px;
}

.time-tip-text {
  color: #333333;
  font-size: 14px;
  font-weight: 400;
}
.blessingText {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-bottom: 16px;
  //flex-wrap: wrap;
  width: 100%;
  gap: 16px;
  .textLabel {
    color: #ff7b00;
    width: 30%;
  }
  .tR {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 16px;
  }
  .blessing {
    background: #fffaf6;
    border-radius: 4px;
    padding: 4px 8px;
    //margin-right: 16px;
  }
  .itemsBel {
    //background: #fffaf6;
    border-radius: 4px;
    padding: 4px 8px;
    //margin-right: 16px;
    color: #999999;
    border: 0.5px solid #EEEEEE;
    background: rgba(238, 238, 238, 0.1);
  }
}
.showBor {
  border: 0.5px solid #ff7b00;
  color: #ff7b00 !important;
  background: #fffaf6 !important;
}
.modalClass /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none;
}
.modalClass /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}
.phoneCalssRight /deep/ a.ant-calendar-ok-btn {
  display: none !important;
}

.phoneCalssRight /deep/ a.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
  display: none !important;
}

</style>
